import { GitHubRepoName } from "@services/publish/InstallationProcess";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "./logo.svg";
import styles from "./Publish.module.css";

// User created extension, but need to add a release for it to be usable
export default function AddRelease() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const repoNameParameter = searchParams.get("repo_name");
  const repoName: GitHubRepoName =
    typeof repoNameParameter === "string"
      ? JSON.parse(repoNameParameter)
      : null;

  const history = useHistory();
  if (!repoName) {
    // Missing repo name, just send to start of publish flow
    history.replace("/publish");
    return null;
  }

  const repoLink = `https://github.com/${encodeURIComponent(
    repoName.owner
  )}/${encodeURIComponent(repoName.name)}/`;
  const newReleaseLink = repoLink + "releases/new";

  return (
    <React.Fragment>
      <img className={styles.logo} src={logo} alt="Saleae Logo" />
      <h2 className={`${styles.prompt} ${styles.above}`}>
        Your extension was submitted, but you need to{" "}
        <a href={newReleaseLink} target="_blank" rel="noopener noreferrer">
          create a release on Github
        </a>{" "}
        before it will be made available to users. You'll receive a confirmation
        email once that's done.
      </h2>
    </React.Fragment>
  );
}
