import { v4 as uuidv4 } from "uuid";

type GitHubCheckNeedInstallResponse =
  | {
      kind: "install";
      redirectUrl: string;
    }
  | { kind: "addPermission"; editUrl: string }
  | { kind: "none" };

interface MarketplaceRelease {
  id: number;
  name: string;
  description: string;
  author: string;
  readmeUrl: string;
  projectUrl: string;
  downloadUrl: string;
  apiVersion: string;
  extensionVersion: string;
}

interface MarketplaceItem {
  id: number;
  githubOwner: string;
  githubRepository: string;
  currentRelease?: MarketplaceRelease;
}

type CreateExtensionResponse =
  | {
      kind: "success";
      item: MarketplaceItem & {
        currentRelease: NonNullable<MarketplaceItem["currentRelease"]>;
      };
    }
  | {
      kind: "withErrors";
      item: MarketplaceItem & {
        currentRelease: NonNullable<MarketplaceItem["currentRelease"]>;
      };
      errors: string[];
    }
  | {
      kind: "noReleases";
      item: MarketplaceItem;
    };

export type InstallCheckResult =
  | {
      kind: "needAlterPermission";
      editUrl: string;
    }
  | {
      kind: "redirect";
      redirectUrl: string;
    };

export type CreateResult =
  | { kind: "success" }
  | { kind: "noReleases" }
  | { kind: "withErrors"; releaseId: number };

// To prevent CSRF (in particular, being able to send someone to a link that publishes a repository on their account) we
// store a random token in local storage and verify it when we come back from the callback
const CSRF_TOKEN_LOCAL_STORAGE_KEY =
  "com.saleae.marketplace.Publish.GitHub.StateToken";

// Encapsulates the process of installing a particular repo, from start to finish.
//
// Due to redirects, this process will not necessarily occur in the same browser tab. We handle state via two mechanisms:
//  * OAuth & the GitHub app installation redirects allows passing a `state` parameter. We serialize the desired repo and a
//    CSRF token and place that in the parameter, allowing this class to be deserialized.
//  * The CSRF token doesn't do much for us if we don't check it against something. We store it in `localStorage` and refuse to
//    deserialize the state if it does not match.
export default class InstallationProcess {
  private readonly csrfToken: string;

  // NOTE: external callers should NEVER specify csrfToken; it is a parameter only because `deserialize` needs
  // to set it.
  constructor(public readonly repoName: GitHubRepoName, csrfToken?: string) {
    if (csrfToken) {
      this.csrfToken = csrfToken;
    } else {
      this.csrfToken = uuidv4();
      window.localStorage.setItem(CSRF_TOKEN_LOCAL_STORAGE_KEY, this.csrfToken);
    }
  }

  // Checks what action needs to be taken to move forward in the install process.
  async installGitHubAppIfNeeded(): Promise<InstallCheckResult> {
    const response = await this.apiPost(
      "marketplace/v1/github/need-org-install",
      {
        githubOwner: this.repoName.owner,
        githubRepository: this.repoName.name,
      }
    );
    switch (response.kind) {
      case "install":
        // The GitHub app is not installed at all on the repo owner
        //
        // Redirect to GitHub app install URL. It will send us back to the appropriate callback, which will include both
        // an OAuth token for the submitting user and the state parameter we specify here, allowing us to continue the process
        // with a request to our API (authenticated by the provided OAuth token).
        return {
          kind: "redirect",
          redirectUrl:
            response.redirectUrl +
            // Serialize this class so it can be recreated on the callback page
            `&state=${encodeURIComponent(JSON.stringify(this.state))}`,
        };
      case "addPermission":
        // The GitHub app is installed on the repo owner, but the installation does not have permissions to the submitted repo
        //
        // In this case we have no suitable redirect URL that will head back to us*. We give the client the appropriate URL to send the
        // user to in a new tab so they can edit the permission, and then retry. At that point the user will hit this API again and receive a
        // `kind: "none"` response.
        //
        // * There is a GitHub feature for that, but we would need to rework our model since it gets triggered
        // with no state parameter whenever the user changes the set of permitted repos. So we would need to somehow create extensions
        // due to webhook installation events instead of explicit requests here.
        return { kind: "needAlterPermission", editUrl: response.editUrl };
      case "none":
        // There is already an installation on the owner of the repo with access to this repo. We do however still need to check that the
        // calling user has permissions on the repo in question.
        // To do so, we need to authenticate the user through GitHub, and then check that they are authorized for this repo on the backend.
        // We do this by redirecting to the GitHub OAuth endpoint, which will call back to us
        // using the OAuth2 authorization code flow. Combined with our CSRF token, this is sufficient to authenticate the user since the OAuth2 code flow
        // (as opposed to the implicit flow) is not subject to code reuse attacks by design.
        //
        // The callback will then deserialize this class from the `state` parameter and call the create extension API endpoint with the OAuth2 code. The server then
        // consumes the code and uses the resulting token's identity to verify that the user is authorized by GitHub to access the GitHub app installation for the
        // repo in question. If that passes, it will attempt to create the extension and inform the user of the release status.
        const redirectUri =
          window.location.origin + "/publish/github-app-callback";
        return {
          kind: "redirect",
          redirectUrl: `https://github.com/login/oauth/authorize?client_id=${encodeURIComponent(
            process.env.REACT_APP_GITHUB_APP_CLIENT_ID!
          )}&redirect_uri=${encodeURIComponent(
            redirectUri
          )}&state=${encodeURIComponent(JSON.stringify(this.state))}`,
        };
      default:
        throw new Error("invalid response kind");
    }
  }

  async createExtension({
    oauthCode,
  }: {
    oauthCode: string;
  }): Promise<CreateResult> {
    const response: CreateExtensionResponse = await this.apiPost(
      "marketplace/v1/item",
      {
        githubOwner: this.repoName.owner,
        githubRepository: this.repoName.name,
        oauthCode,
      }
    );

    if (response.kind === "withErrors") {
      return { kind: "withErrors", releaseId: response.item.currentRelease.id };
    } else {
      return { kind: response.kind };
    }
  }

  get state() {
    return {
      csrfToken: this.csrfToken,
      repoName: this.repoName,
    };
  }

  private async apiPost(uri: string, body: any): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${uri}`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`Received status ${response.status} from ${uri}`);
    }
    return await response.json();
  }

  static deserialize(stateString: string): InstallationProcess | null {
    let state: InstallationProcess["state"];
    try {
      state = JSON.parse(stateString);
    } catch (e) {
      if (e instanceof SyntaxError) {
        return null;
      } else {
        throw e;
      }
    }
    if (
      !("repoName" in state) ||
      state.csrfToken !==
        window.localStorage.getItem(CSRF_TOKEN_LOCAL_STORAGE_KEY)
    ) {
      return null;
    }
    return new InstallationProcess(state.repoName, state.csrfToken);
  }
}

export interface GitHubRepoName {
  owner: string;
  name: string;
}

export function parseGithubLink(gitHubLink: string): GitHubRepoName | null {
  const match = /^((https?:\/\/)?(www\.)?github\.com\/|(ssh:\/\/)?git@(www\.)?github\.com:)(?<owner>[^/]+)\/(?<name>[^/]+?)(\.git)?(\/?)$/.exec(
    gitHubLink
  );
  if (!match || !match.groups) {
    return null;
  }
  return {
    owner: decodeURIComponent(match.groups.owner),
    name: decodeURIComponent(match.groups.name),
  };
}
