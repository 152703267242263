import React from "react";
import styles from "./Publish.module.css";
import logo from "./logo.svg";

// Extension added succesfully
export default function Success() {
  return (
    <React.Fragment>
      <img className={styles.logo} src={logo} alt="Saleae Logo" />
      <h2 className={`${styles.prompt} ${styles.above}`}>
        Extension submitted successfully!
      </h2>
    </React.Fragment>
  );
}
