import React from "react";
import { useLocation } from "react-router-dom";
import logo from "./logo.svg";
import styles from "./Publish.module.css";

// Uses a `release_id` obtained from an extension create call to provide a stable URL that displays the errors, if any,
// that prevented the release from validating succesfully.
export default function ValidationError() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const releaseIdParameter = searchParams.get("release_id");

  const [errors, setErrors] = React.useState(null);
  React.useEffect(() => {
    if (releaseIdParameter === null) {
      return;
    }
    let canceled = false;

    (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}marketplace/v1/release/status/${releaseIdParameter}`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Received status ${response.status} from status URI`);
      }

      const { errors } = await response.json();

      if (!canceled) {
        setErrors(errors);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [releaseIdParameter]);

  return (
    <React.Fragment>
      <img className={styles.logo} src={logo} alt="Saleae Logo" />
      <h2 className={`${styles.prompt} ${styles.above}`}>
        There were some errors submitting your extension, please correct them
        and then create another GitHub release. You can then re-submit your
        extension to view the status of the new release.
      </h2>
      <ul className={styles.error_list}>
        {(errors ?? []).map((error, index) => {
          return <li key={index}>{error}</li>;
        })}
      </ul>
    </React.Fragment>
  );
}
