import InstallationProcess, {
  parseGithubLink,
  InstallCheckResult,
} from "@services/publish/InstallationProcess";
import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import GitHubAppCallback from "./github-app-callback";
import logo from "./logo.svg";
import styles from "./Publish.module.css";
import Success from "./success";
import AddRelease from "./add-release";
import ValidationError from "./validation-error";

const GITHUB_URL_HINT_PARAM = "github_repo_url";

export default function PublishContainer() {
  const { path } = useRouteMatch();

  return (
    <div className={styles.page_container}>
      <div className={styles.publish_top}>
        <Switch>
          <Route exact path={path}>
            <PublishLanding />
          </Route>
          <Route exact path={`${path}/github-app-callback`}>
            <GitHubAppCallback />
          </Route>
          <Route exact path={`${path}/success`}>
            <Success />
          </Route>
          <Route exact path={`${path}/add-release`}>
            <AddRelease />
          </Route>
          <Route exact path={`${path}/validation-error`}>
            <ValidationError />
          </Route>
        </Switch>
      </div>
      <div className={styles.publish_bottom}>
        <h2>How it works?</h2>
        <div className={styles.steps}>
          <div>
            <h4>Step 1</h4>
            <p className={styles.subtitle}>Submit your GitHub link</p>
            <p className={styles.content}>
              Grab a link to a public GitHub repository you want to publish
              (either from the address bar or a clone link) and paste it above.
            </p>
            <p className={styles.content}>
              By publishing you agree to the{" "}
              <a
                href="https://downloads.saleae.com/Saleae+Developer+Terms.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </p>
          </div>
          <div>
            <h4>Step 2</h4>
            <p className={styles.subtitle}>Approve Saleae</p>
            <p className={styles.content}>
              You will be redirected to GitHub to approve read-only access to
              the repository. Once you're done you'll automatically be sent back
              here.
            </p>
          </div>
          <div>
            <h4>Step 3</h4>
            <p className={styles.subtitle}>Create a Release on GitHub</p>
            <p className={styles.content}>
              If you haven't already created a release for the repository on
              GitHub, you'll be prompted to do so. Then, come back here to view
              the status of your extension.
              <br />
              <br />
              Otherwise, refresh the extension list in the Logic 2 app and
              enjoy!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function PublishLanding() {
  // Get default value for field from URL parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const gitHubUrlDefaultValue = searchParams.get(GITHUB_URL_HINT_PARAM);

  const githubUrlInputRef = React.useRef<HTMLInputElement>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [
    installResult,
    setInstallResult,
  ] = React.useState<InstallCheckResult | null>(null);

  const onSubmit = React.useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!githubUrlInputRef.current) {
      return;
    }

    const repoName = parseGithubLink(githubUrlInputRef.current.value);
    if (!repoName) {
      // FIXME: don't use alert box
      alert("Invalid repository URL");
      return;
    }

    // Prevent concurrent submit
    setSubmitting(true);
    try {
      const process = new InstallationProcess(repoName);
      // Determine what action we need to take, if any, to get the GitHub app installed on the repo
      const result = await process.installGitHubAppIfNeeded();
      if (result.kind === "redirect") {
        // We either:
        //  * Need to install the app into the given org/user. Redirect them to that install flow,
        //    they'll end up back here due to the callback URL configured in the GitHub app settings.
        //  * Already have permissions to the repo, so all we need to do is validate the user also has permission. Redirect
        //    to the GitHub OAuth flow.
        // `InstallationProcess` handles these details and produces an appropriate redirect URL.
        setInstallResult(null);
        window.location.href = result.redirectUrl;
      } else if (result.kind === "needAlterPermission") {
        // The org/user that owns the repo has the app installed, but haven't given the installation permission for this
        // repo. We can't redirect them (they wouldn't ever come back) but explain the situation and give them a link to the
        // page where they can update the permissions.
        setInstallResult(result);
      }
    } catch (err) {
      // FIXME: don't use alert box
      alert("Failed to submit repository");
      throw err;
    } finally {
      setSubmitting(false);
    }
  }, []);

  let statusDisplay: JSX.Element | null = null;
  if (installResult?.kind === "needAlterPermission") {
    statusDisplay = (
      <h2 className={`${styles.prompt} ${styles.below}`}>
        You've installed our GitHub app on your organization, but you haven't
        approved access to this repo. Please add it{" "}
        <a
          href={installResult.editUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{" "}
        and then try again .
      </h2>
    );
  } else if (submitting) {
    statusDisplay = (
      <h2 className={`${styles.prompt} ${styles.below}`}>Processing...</h2>
    );
  }

  return (
    <React.Fragment>
      <img className={styles.logo} src={logo} alt="Saleae Logo" />
      <h2 className={`${styles.prompt} ${styles.above}`}>
        Submit the GitHub link of your extension, then approve Saleae
        Marketplace
      </h2>
      <form className={styles.submit_form} action="#" onSubmit={onSubmit}>
        {/* Some browser compatibility notes:
              - autocomplete="off" is needed to prevent safari from trying to capitalize github.
         */}
        <input
          ref={githubUrlInputRef}
          type="text"
          placeholder="Github URL..."
          defaultValue={gitHubUrlDefaultValue ?? undefined}
          disabled={submitting}
          autoComplete="off"
        />
        <input type="submit" value="Submit" disabled={submitting} />
      </form>
      {statusDisplay}
    </React.Fragment>
  );
}
