import InstallationProcess, {
  CreateResult,
} from "@services/publish/InstallationProcess";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Publish.module.css";
import logo from "./logo.svg";

// Just a wrapper that validates that we have a code and state parameter.
//
// See `GitHubAppCallbackInner` for details of operation.
export default function GitHubAppCallback() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stateParameter = searchParams.get("state");
  const oauthCode = searchParams.get("code");

  const installation = React.useMemo(() => {
    if (typeof stateParameter !== "string") {
      return null;
    }
    return InstallationProcess.deserialize(stateParameter);
  }, [stateParameter]);

  const history = useHistory();
  if (installation === null || oauthCode === null) {
    history.replace("/publish");
    return null;
  }

  return <GitHubAppCallbackInner {...{ oauthCode, installation }} />;
}

// This gets called by all GitHub app callbacks, two of which we use:
//  * GitHub app installation
//  * OAuth2 authentication
//
// In both cases, we should have a serialized `InstallationProcess` in the state parameter and an OAuth2 code. This
// signifies the user's intent (verified through the CSRF token built into `InstallationProcess`) to add the repo named
// in the state token as an extension. We trigger an API request to add the extension, passing the OAuth2 code as evidence
// of both authorization of the
//  * User's intent via
//    1. The built in guarantees of the OAuth2 Authorization Code flow, which prevents code reuse attacks and verifies the code's veracity
//       when convering it into a token.
//    2. The CSRF token verification we perform in combination with the CORS guarantee that if the API request comes from
//       the user's browser, it comes from JavaScript hosted on our origin.
//  * Users's permission to add the repo, via explicit server-side verification with the GitHub API using the token produced by the OAuth2 flow.
function GitHubAppCallbackInner({
  oauthCode,
  installation,
}: {
  oauthCode: string;
  installation: InstallationProcess;
}) {
  const history = useHistory();
  const requestCallback = React.useRef<((res: CreateResult) => void) | null>(
    null
  );

  React.useEffect(() => {
    // Only make the request once
    let madeReqeust = requestCallback.current !== null;
    requestCallback.current = (res: CreateResult) => {
      // Note that we use `history.replace` so we don't leave a redirecting url with a now dead OAuth token in the browser history
      if (res.kind === "success") {
        // Publish succeeded, just show a friendly message
        history.replace("/publish/success");
      } else if (res.kind === "noReleases") {
        // Extension was created, but there are no GitHub releases on the given repo. Prompt the user to add them. The releases should
        // be added automatically by the webhook, but the user can verify the status by submitting the extension again.
        history.replace(
          `/publish/add-release?repo_name=${encodeURIComponent(
            JSON.stringify(installation.repoName)
          )}`
        );
      } else if (res.kind === "withErrors") {
        // Extension was created, but the latest GitHub release failed validation. Give the user feedback so they can fix the problems and issue
        // a new release.
        history.replace(
          `/publish/validation-error?release_id=${res.releaseId}`
        );
      }
    };
    if (!madeReqeust) {
      (async () => {
        try {
          const state = await installation.createExtension({ oauthCode });
          requestCallback.current?.(state);
        } catch (err) {
          // TODO: backend error codes -> better displayed messages
          alert("There was an error processing your request, please try again");
          throw err;
        }
      })();
    }
    // None of these parameters should change, but in theory history could. Keep them here to make the linter happy.
  }, [oauthCode, installation, history]);

  return (
    <React.Fragment>
      <img className={styles.logo} src={logo} alt="Saleae Logo" />
      <h2 className={`${styles.prompt} ${styles.above}`}>Processing...</h2>
    </React.Fragment>
  );
}
