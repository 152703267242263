import PublishPage from "@services/publish/pages";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import styles from "./App.module.css";

function App() {
  return (
    <Router>
      {window.analytics && <SegmentTracker />}
      <div className={styles.app}>
        <Switch>
          <Route path="/" exact>
            <PublishPage />
          </Route>
          <Route path="/publish">
            <PublishPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// NOTE: this has to be in a separate component because it needs to be inside a `<Router/>` to use
// the `useHistory` hook.
function SegmentTracker() {
  const history = useHistory();

  // Send page views to Segment
  React.useEffect(() => {
    window.analytics.page(history.location.pathname);
    return history.listen((action) => {
      window.analytics.page(action.pathname);
    });
  }, [history]);

  return null;
}

export default App;
