import "core-js";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";
import "./segment";

Sentry.init({
  dsn:
    "https://30432e53a2854dbc98639f7fc3d8fd52@o88880.ingest.sentry.io/5254421",
  environment:
    process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV || "development",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
